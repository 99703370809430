<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        div(v-if="isEdit")
          h5(class="account-name") {{ dataClone.profile.account_name }}＜プロフィール＞

          div(class="account-icon")
            <pan-thumb :avatar="data.profile.avatar_store_path" :attribute="data.profile.attribute"/>
            input#fileUpload(type="file", v-show="false", @change="onUpload")
            label(@click="chooseFile()")
              i.fas.fa-camera

          div(class="caution-t" style="text-align:center") ※比率1:1、300KB以上の際は圧縮いたします（推奨サイズ：200×200ピクセル、300KB以下）

          div(class="account-prof-change-content") アカウントID: {{ data.profile.account_id }}
            span(class="m-l") ※IDの変更は運営の方のみが行えます

          div(v-if="!isNamePassCheck" style="color:red;margin-top:20px") NGワードが含まれています
          div(class="account-prof-change-content") アカウント名
            span(class="caution-t") *
            span(class="m-l") ※全角20文字、半角40文字以内
            input(v-model="data.profile.account_name" class="input-g-w-border" @input="checkNameInput()")

          div(class="account-prof-change-content") 属性
            div
              select(v-model="data.profile.attribute" class="input-g-w-border")
                option(v-for="option in ui.type" :value="option.value") {{ option.name }}

          div(v-if="!isIntroductionPassCheck" style="color:red;margin-top:20px") NGワードが含まれています
          div(class="account-prof-change-content") 自己紹介文
            span(class="m-l") ※全角160文字、半角320文字以内
            div(class="account-prof-change-content-textcontent")
              textarea(v-model="data.profile.introduction" type="text" maxlength="320" rows="3" class="input-g-w-border" @input="checkIntroductionInput()")
              div(class="account-prof-change-content-textcounter") {{data.profile.introduction ? data.profile.introduction.length : 0}}/320

          div(class="caution-t") ※＊は必須項目です

          component-error(:errors="errors" v-if="errors.length")
          div(class="account-button")
            img(@click="onConfirm()" class="img-button" src="/img/koushin_p.png")

        div(v-if="!isEdit")
          div(class="account-icon")
            <pan-thumb :avatar="data.profile.avatar_store_path" :attribute="data.profile.attribute"/>
          div(class="m-b") アカウントID:{{ data.profile.account_id }}
          div(class="m-b") アカウント名:{{ data.profile.account_name }}
          div(class="m-b") 属性：{{ attrString }}
          div(class="m-b") 自己紹介文：{{ data.profile.introduction }}
          div(class="m-b") Twitterリンク:{{ data.profile.sns_infos.twitter }}
          div(class="m-b") Instagramリンク:{{ data.profile.sns_infos.instagram }}
          div(class="m-b") Youtubeリンク:{{ data.profile.sns_infos.youtube }}
          div(class="t-c m-t") この内容でよろしいですか？

          div(class="account-button")
            img(@click="onSubmit()" class="img-button" src="/img/yes_p.png")
            img(@click="onCancel()" class="img-button" src="/img/no_p.png")

      div(class="content-right")
        div(class="pink-label") SNSリンク
        div(class="right-content-border")
          div(class="sns-link-content")
            div(class="sns-link-content-item")
              img(src="/img/socil_twitter.png")
              input(v-model="data.profile.sns_infos.twitter" class="input-g-w-border")

          div(class="sns-link-content")
            div(class="sns-link-content-item")
              img(src="/img/socil_instagram.png")
              input(v-model="data.profile.sns_infos.instagram" class="input-g-w-border")

          div(class="sns-link-content")
            div(class="sns-link-content-item")
              img(src="/img/socil_youtube.png")
              input(v-model="data.profile.sns_infos.youtube" class="input-g-w-border")

          div(class="sns-link-button")
            img(@click="onSubmit()" class="img-button" src="/img/hozon_p.png")

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { setUserInfoFromProfile, getUserInfoFromProfile, uploadAvatar } from '@/api/user'
import { getSystemNgWord } from '@/api/system';
import { validStringCount } from '@/utils/inputLimit';
import { getImageUrl } from '@/utils/tools'
import PanThumb from '@/components/PanThumb'
import Compressor from 'compressorjs';
import { cloneDeep } from 'lodash';
import Mint from 'mint-filter'

export default {
  name: "ProfileIndex",
  components: {
    PanThumb
  },
  data() {
    return {
      ui: {
        type: []
      },
      data: {
        profile: {
          sns_infos: {
            twitter: '',
            instagram: '',
            youtube: '',
          }
        }
      },
      dataClone: {
        profile: {
          sns_infos: {
            twitter: '',
            instagram: '',
            youtube: '',
          }
        }
      },
      isEdit: true,
      errors: [],
      form: {
        profileImage: '/img/test.png',
        accountId: '文字 アカウント ID',
        name: undefined,
        profile: undefined,
        content: undefined,
      },
      file:undefined,
      NgWordList: [],
      mint: {},
      isMintInited: false,
      isNamePassCheck: true,
      isIntroductionPassCheck: true,
    }
  },
  props: {},
  computed: {
    avatarImageUrl(){
      return getImageUrl(this.data.profile.avatar_store_path)
    },
    attrString() {
      if (this.data.profile.attribute === this.$store.state.system.enum_list.attribute_list.NONE) {
        return '無属性';
      } else if (this.data.profile.attribute === this.$store.state.system.enum_list.attribute_list.FIRE) {
        return '炎属性';
      } else if (this.data.profile.attribute === this.$store.state.system.enum_list.attribute_list.ICE) {
        return '氷属性';
      } else if (this.data.profile.attribute === this.$store.state.system.enum_list.attribute_list.WIND) {
        return '風属性';
      } else if (this.data.profile.attribute === this.$store.state.system.enum_list.attribute_list.EARTH) {
        return '地属性';
      }
      return '';
    },
  },
  watch: {},
  mounted() {},
  async created() {
    this.ui.type = [
      { name: '無属性', value: this.$store.state.system.enum_list.attribute_list.NONE },
      { name: '炎属性', value: this.$store.state.system.enum_list.attribute_list.FIRE },
      { name: '氷属性', value: this.$store.state.system.enum_list.attribute_list.ICE },
      { name: '風属性', value: this.$store.state.system.enum_list.attribute_list.WIND },
      { name: '地属性', value: this.$store.state.system.enum_list.attribute_list.EARTH },
    ];

    await this.syncData();
    this.initMint()
  },
  updated() {},
  destroyed() {},
  methods: {
    async syncData() {
      const ret = await getUserInfoFromProfile();
      if (!ret.data.profile.sns_infos) {
        ret.data.profile.sns_infos = {
          twitter: '',
          instagram: '',
          youtube: '',
        }
      }
      this.data = ret.data;
      this.dataClone = cloneDeep(ret.data);
      this.nameOld = this.data.name;
    },

    async onSubmit() {
      this.checkNameInput()
      this.checkIntroductionInput()
      if(!this.isNamePassCheck || !this.isIntroductionPassCheck) {
        this.$alert('NGワードが含まれています')
        return
      }
      if (!this.socialValidation()) { return }
      let form = {
        profile: {
          account_name: this.data.profile.account_name,
          attribute: this.data.profile.attribute,
          introduction: this.data.profile.introduction,
          sns_infos: {
            twitter: this.data.profile.sns_infos.twitter || '',
            instagram: this.data.profile.sns_infos.instagram || '',
            youtube: this.data.profile.sns_infos.youtube || '',
          },
        }
      };
      if (this.data.profile.avatar_store_path) {
        form.profile.avatar_store_path = this.data.profile.avatar_store_path;
      }
      await setUserInfoFromProfile(form);
      await this.syncData();
      this.$message.success('更新に成功しました')
      this.isEdit = true;
    },
    onConfirm() {
      this.errors = [];
      let errorStrings = [];

      this.checkNameInput()
      this.checkIntroductionInput()
      if(!this.isNamePassCheck || !this.isIntroductionPassCheck) {
        errorStrings.push('NGワードが含まれています');
      }
      if (!validStringCount(this.data.profile.account_name, 40)) {
        errorStrings.push('※[アカウント名]は全角20文字、半角40文字以内で入力してください');
      }
      if (!validStringCount(this.data.profile.introduction, 320)) {
        errorStrings.push('※[自己紹介文]は全角160文字、半角320文字以内で入力してください');
      }
      if (errorStrings.length) {
        this.errors = errorStrings;
        return false;
      }
      if (!this.socialValidation()) { return }

      this.isEdit = false;
      return true;
    },
    socialValidation() {
      this.errors = [];
      let errorStrings = [];
      const twitterReg = /^(https|http):\/\/twitter.com\/\.*/
      const isTwitter = twitterReg.test(this.data.profile.sns_infos.twitter)
      if (this.data.profile.sns_infos.twitter && !isTwitter) {
        errorStrings.push('https://twitter.com/から始まるリンク以外は設定できません');
      }
      const instagramReg = /^(https|http):\/\/www.instagram.com\/\.*/
      const isInstagram = instagramReg.test(this.data.profile.sns_infos.instagram)
      if (this.data.profile.sns_infos.instagram && !isInstagram) {
        errorStrings.push('https://www.instagram.com/から始まるリンク以外は設定できません');
      }
      const youtubeReg = /^(https|http):\/\/www.youtube.com\/\.*/
      const isYoutube = youtubeReg.test(this.data.profile.sns_infos.youtube)
      if (this.data.profile.sns_infos.youtube && !isYoutube) {
        errorStrings.push('https://www.youtube.com/から始まるリンク以外は設定できません');
      }
      if (errorStrings.length) {
        this.errors = errorStrings;
        return false;
      }
      return true;
    },
    onCancel() {
      this.isEdit = true;
    },

    chooseFile() {
      document.getElementById("fileUpload").click();
    },
    async onUpload(event) {
      let _this = this;
      this.file = event.target.files[0];
      if (!this.file) {
        return;
      }
      let imagSize = this.file.size;
      if(imagSize < 1024 * 1024 * 3) {
        var formData = new FormData();
        formData.append("file", event.target.files[0]);

        try {
          const { data } = await uploadAvatar(formData);
          _this.data.profile.avatar_store_path = data.store_path;
        }catch(e) {
          console.log(e)
          _this.$alert('アップロードに失敗しました。不正なファイル形式です。')
        }

      } else {
        new Compressor(this.file, {
        convertSize: 3000000, // 3MB
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        async success(result) {
          var formData = new FormData();
          formData.append("file", result);
          if (formData) {
            try { 
              const { data } = await uploadAvatar(formData);
              _this.data.profile.avatar_store_path = data.store_path;
            }catch(e) {
              console.log(e)
              _this.$alert('アップロードに失敗しました。不正なファイル形式です。')
            }
          }
        },
        error(err) {
          console.log(err.message);
        }
        })
      }
    },

    // NGワードチャックInit
    async initMint(){
      this.isMintInited = false
      const { data } = await getSystemNgWord()
      this.NgWordList = data
      this.mint = new Mint(this.NgWordList)
      this.isMintInited = true
    },

    // 入力のタイミングでNGワードチャック name
    checkNameInput(){
      const regMail = /[\w\-._]+@[\w\-._]+\.[A-Za-z]+/g
      const regUrl = /https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/g
      this.isNamePassCheck = this.mint.validator(this.data.profile.account_name) && !regMail.test(this.data.profile.account_name) && !regUrl.test(this.data.profile.account_name)
    },

    // 入力のタイミングでNGワードチャック introduction
    checkIntroductionInput(){
      const regMail = /[\w\-._]+@[\w\-._]+\.[A-Za-z]+/g
      const regUrl = /https?:\/\/[\w!?/+\-_~;.,*&@#$%()'[\]]+/g
      this.isIntroductionPassCheck = this.mint.validator(this.data.profile.introduction) && !regMail.test(this.data.profile.introduction) && !regUrl.test(this.data.profile.introduction)
    },
  }
};
</script>
